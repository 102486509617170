import React, { useState } from 'react';
import { FaEllipsisV } from 'react-icons/fa'; // For the 3 dots button
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const MoreButton = ({ dataset, onFilterChange , filterByYear = false}) => {
  const [showPopup, setShowPopup] = useState(false);
  const [filterOption, setFilterOption] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [showDateRange, setShowDateRange] = useState(false);
  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 5 }, (_, index) => currentYear - index); // Generate years from current year backwards

  const [selectedYear, setSelectedYear] = useState(currentYear); // State to track the selected year


  const togglePopup = () => {
    setShowPopup(!showPopup);
    setShowDateRange(false);
  };


  const handleOptionClick = (option) => {
    setFilterOption(option);
    if (option === 'Date Range') {
      setShowDateRange(true);
    } else {
      setShowDateRange(false);
      onFilterChange(dataset, option); // Trigger filter change for a specific dataset
      setShowPopup(false);
    }
  };

  const applyDateRangeFilter = () => {
    setShowPopup(false);
    onFilterChange(dataset, { filter: 'Date Range', startDate, endDate }); // Trigger Date Range filter for a specific dataset
  };

  const handleYearChange = (e) => {
    setSelectedYear(e.target.value); 
    onFilterChange(dataset, { filter: 'Year', year: e.target.value }); 
    setShowPopup(false);
    // You can add additional logic to filter data based on the selected year here
  };

  return (
    <div className="more-button-container" style={{ position: 'relative', display: 'inline-block' }}>
      {/* 3 Dots Button */}
      <button onClick={togglePopup} className="more-button">
        <FaEllipsisV />
      </button>

      {/* Popup with Filter Options */}
      {showPopup && (
        <div className="popup-menu" style={popupStyles}>
          {filterByYear && (
            <ul style={{ listStyleType: 'none', padding: 0 }}>
              <li style={optionStyles}>
                Filter by year
              </li>
              {/* add 5 select for years  starting from current backwards*/}
              <li style={optionStyles}>
                <select value={selectedYear} onChange={handleYearChange} style={{ padding: '8px', width: '100%' }}>
                  {years.map((year) => (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  ))}
                </select>
              </li>
            </ul>
          )}
          {!filterByYear && (
          <ul style={{ listStyleType: 'none', padding: 0 }}>
            <li onClick={() => handleOptionClick('This Month')} style={optionStyles}>
              This Month
            </li>
            <li onClick={() => handleOptionClick('This Year')} style={optionStyles}>
              This Year
            </li>
            <li onClick={() => handleOptionClick('Date Range')} style={optionStyles}>
              Date Range
            </li>
          </ul>
          )}
          
          {showDateRange && (
            <div style={dateRangeContainerStyles}>
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                placeholderText="Start Date"
              />
              <DatePicker
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                placeholderText="End Date"
              />
              <button onClick={applyDateRangeFilter} style={applyButtonStyles}>
                Apply
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

const popupStyles = {
  position: 'absolute',
  top: '30px',
  right: '0',
  backgroundColor: '#fff',
  border: '1px solid #ccc',
  borderRadius: '4px',
  padding: '10px',
  zIndex: 1000,
  width: '200px',
};

const optionStyles = {
  padding: '10px',
  cursor: 'pointer',
};

const dateRangeContainerStyles = {
  marginTop: '10px',
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
};

const applyButtonStyles = {
  marginTop: '10px',
  backgroundColor: '#007bff',
  color: '#fff',
  border: 'none',
  padding: '5px 10px',
  cursor: 'pointer',
  borderRadius: '4px',
};

export default MoreButton;
