
import React, { useRef } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import 'ckeditor5/ckeditor5.css';

export const PostSmartEditor = ({ data, updateData }) => {
    const editorRef = useRef(null);
    return (
        <CKEditor
            editor={ClassicEditor}
            data={data.content}
            onReady={(editor) => {
                editorRef.current = editor;
            }}
            onChange={(event, editor) => {                
                const content = editor.getData();
                updateData({ ...data, content: content });
            }}
        />
    );
};
