import React from 'react';
import { Line, Bar, Pie } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, BarElement, ArcElement, Title, Tooltip, Legend } from 'chart.js';

// Register scales and elements
ChartJS.register(
  CategoryScale,   // For categorical x-axis
  LinearScale,     // For linear y-axis
  PointElement,    // For points in line chart
  LineElement,     // For line chart lines
  BarElement,
  ArcElement,
  Title,           // Chart title
  Tooltip,         // Tooltips
  Legend           // Legends
);

const UserActivityLineChart = ({ data }) => {
  if (!data || data.length === 0) {
    return <p>No data available</p>; // Fallback when data is not available
  }
  const labels = data?.map(item => item.date);  
  const activeUsersData = data?.map(item => item.active_users);  
  const newUsersData = data?.map(item => item.new_users);   

  const chartData = {
    labels: labels,
    datasets: [
      {
        label: 'New Users',
        data: newUsersData,
        borderColor: 'rgba(153, 102, 255, 1)',  // Color for New Users
        backgroundColor: 'rgba(153, 102, 255, 0.2)',
        fill: false,  // Set to false for no area fill
        tension: 0.1,  // Smooth lines
      },
      {
        label: 'Active Users',
        data: activeUsersData,
        borderColor: 'rgba(75, 192, 192, 1)',  // Color for Active Users
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        fill: false,  // Set to false for no area fill
        tension: 0.1,  // Smooth lines
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: "New vs Active Users Over Time",
      },
      legend: {
        position: 'top',
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Date',
        },
      },
      y: {
        title: {
          display: true,
          text: 'Users',
        },
        beginAtZero: true,
      },
    },
  };

  return <Line data={chartData} options={options} />;
};

const BarChart = ({ data }) => {
  if (!data || data.length === 0) {
    return <p>No data available</p>; // Fallback when data is not available
  }

  const channels = data.data.map(item => item.firstUserPrimaryChannelGroup);   
  const newUsersData = data.data.map(item => item.newUsers); 
  const chartData = {
    labels: channels,  
    datasets: [
      {
        label: 'New Users',
        data: newUsersData,  
        backgroundColor: [
          'rgba(255, 99, 132, 0.6)',
          'rgba(54, 162, 235, 0.6)',
          'rgba(255, 206, 86, 0.6)',
          'rgba(75, 192, 192, 0.6)',
        ],
      },
    ],
  };
  const options = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: 'New Users by Acquisition Source',
      },
      legend: {
        position: 'top',
      },
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  }

  return <Bar data={chartData} options={options}/>;
};

const UserByCountryBarChart = ({ data }) => {
  if (!data || data.length === 0) {
    return <p>No data available</p>; // Fallback when data is not available
  }

  const channels = data.map(item => item.country);   
  const dataPoints = data.map(item => item.active_users); 
  const chartData = {
    labels: channels,  
    datasets: [
      {
        label: 'Active Users',
        data: dataPoints,  
        backgroundColor: [
          'rgba(255, 99, 132, 0.6)',
          'rgba(54, 162, 235, 0.6)',
          'rgba(255, 206, 86, 0.6)',
          'rgba(75, 192, 192, 0.6)',
        ],
      },
    ],
  };
  const options = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: 'Active Users by Country',
      },
      legend: {
        position: 'top',
      },
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };
  return <Bar data={chartData} options={options}/>;
};


const PieChart = ({ data }) => {
  const chartData = {
    labels: data.channels,  // e.g., ['Organic Search', 'Direct', 'Referral', 'Organic Social']
    datasets: [
      {
        label: 'Sessions',
        data: data.sessions,  // e.g., [518, 265, 44, 24]
        backgroundColor: [
          'rgba(255, 99, 132, 0.6)',
          'rgba(54, 162, 235, 0.6)',
          'rgba(255, 206, 86, 0.6)',
          'rgba(75, 192, 192, 0.6)',
        ],
        hoverOffset: 4,
      },
    ],
  };

  return <Pie data={chartData} />;
};

const RetentionCharts = ({ data }) => {
  if (!data || data.length === 0) {
    return <p>No data available</p>; // Fallback when data is not available
  }

  const labels = data.data.map(item => `Week ${parseInt(item.nthWeek)}`);  
  const dataPoints = data.data.map(item => item.cohortActiveUsers); 

  const chartData = {
    labels: labels,
    datasets: [{
      label: `Cohort 1`,
      data: dataPoints, 
      backgroundColor: `rgba(${75 * 40}, 192, 192, 0.6)`,
    }
  ],
  };

  const options = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: 'Retention Rate by Week',
      },
      legend: {
        position: 'top',
      },
    },
    scales: {
      x: { stacked: true },
      y: { stacked: true },
    },
  };

  return <Bar data={chartData} options={options} />;
};
const RetentionChart = ({ data }) => {
  if (!data || data.length === 0) {
    return <p>No data available</p>; // Fallback when data is not available
  }
  // transform the data
  const transformData = (data) => {
    const cohorts = {};

    // Loop through each entry to organize by cohort and week
    data.data.forEach((entry) => {
        const { cohort, nthWeek, cohortActiveUsers } = entry;

        // Initialize cohort if it doesn't exist
        if (!cohorts[cohort]) {
            cohorts[cohort] = { label: cohort, data: Array(5).fill(0) }; // Assuming max 5 weeks for simplicity
        }

        // Set active users for the corresponding week
        cohorts[cohort].data[parseInt(nthWeek, 10)] = parseInt(cohortActiveUsers, 10);
    });

    // Prepare labels and datasets for Chart.js
    return {
        labels: ["Week 0", "Week 1", "Week 2", "Week 3", "Week 4"],
        datasets: Object.values(cohorts),
    };
};
const transformedDatasets = transformData(data);

  const chartData = {
      labels: transformedDatasets.labels,
      datasets: transformedDatasets.datasets
      .map((dataset, index) => ({
          label: dataset.label,
          data: dataset.data,
          backgroundColor: dataset.data.map(value => `rgba(0, 123, 255, ${value / 100})`),  // Adjust opacity based on active users
      }))
  };

  const options = {
      responsive: true,
      plugins: {
          legend: {
              display: true,
          },
          tooltip: {
              callbacks: {
                  label: (context) => `Active Users: ${context.raw}`,
              }
          }
      },
      scales: {
          x: {
              stacked: true,
              title: {
                  display: true,
                  text: 'Week',
              },
          },
          y: {
              stacked: true,
              title: {
                  display: true,
                  text: 'Cohort',
              },
          },
      }
  };

  return <Bar data={chartData} options={options} />;
};
const PageViewsBarChart = ({ data }) => {
  if (!data.data || data.data.length === 0) {
    return <p>No data available</p>; // Fallback when data is not available
  }

  // Helper function to trim the label
  const trimLabel = (label) => {
    if (label.length > 10) {
      return label.substring(0, 10) + '...';  // Trim label to 10 characters and add ellipsis
    }
    return label;
  };

  const labels = data.data.map(item => trimLabel(item.pageTitle)); // Trim labels for x-axis
  const fullLabels = data.data.map(item => item.pageTitle);  // Full labels for tooltip
  const dataPoints = data.data.map(item => item.sessions);

  const chartData = {
    labels: labels,  // Trimmed labels for x-axis
    datasets: [
      {
        label: 'Page Views',
        data: dataPoints,
        backgroundColor: [
          'rgba(255, 99, 132, 0.6)',
          'rgba(54, 162, 235, 0.6)',
          'rgba(255, 206, 86, 0.6)',
        ],
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {      
      title: {
        display: true,
        text: "Page Views and Session Distribution Across Website Pages",
      },
      tooltip: {
        callbacks: {
          // Custom tooltip to display the full label on hover
          title: function (context) {
            const index = context[0].dataIndex;
            return fullLabels[index]; // Show full label on hover
          },
        },
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Page Title',
        },
      },
      y: {
        title: {
          display: true,
          text: 'Sessions',
        },
        beginAtZero: true,
      },
    },
  };

  return <Bar data={chartData} options={options} />;
};

export {UserActivityLineChart, BarChart, UserByCountryBarChart, PieChart, RetentionChart, PageViewsBarChart};
