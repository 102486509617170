import React, { useState, useEffect } from 'react';
import { useSnackbar } from "notistack";
import { apiRequest } from "../../api/api-calls";
import { UserActivityLineChart, BarChart, UserByCountryBarChart, RetentionChart, PageViewsBarChart } from "./monitoring/ga";
import { ActiveUserActivityLineChart, ActiveUsersLineChart, KPIChart,StackedBarChart, MilestonesChart, MilestonesChartByEntity } from "./monitoring/gcf";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import MoreButton from "../../components/MoreButton";
import 'react-tabs/style/react-tabs.css';

export const Analytics = () => {

  const { enqueueSnackbar } = useSnackbar();

  // const [analyticsData, setAnalyticsData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [activeUsers, setActiveUsers] = useState(null);
  const [activeGCFUsers, setActiveGCFUsers] = useState(null);
  const [usersByCountry, setUsersByCountry] = useState(null);
  const [newUsers, setNewUsers] = useState(null);
  const [topEvents, setTopEvents] = useState(null);
  const [trafficAcquisition, setTrafficAcquisition] = useState(null);
  const [trafficAcquisitionGCF, setTrafficAcquisitionGCF] = useState(null);
  const [userRetention, setUserRetention] = useState(null);
  const [countryData, setCountryData] = useState(null);
  const [kpis_per_quarter, setKpis_per_quarter] = useState(null);
  const [kpis_per_organization, setKpis_per_organization] = useState(null);
  const [milestones_per_quarter, setMilestones_per_quarter] = useState(null);
  const [milestones_per_organization, setMilestones_per_organization] = useState(null);
  const [tabIndex, setTabIndex] = useState(0);

  useEffect(() => {
    apiRequest(
      "post",
      "api/active-users/",
      {},
      setActiveUsers,
      enqueueSnackbar
    );
    apiRequest(
      "post",
      "api/users-country/",
      {},
      setUsersByCountry,
      enqueueSnackbar
    );
    apiRequest(
      "post",
      "api/new-users/",
      {},
      setNewUsers,
      enqueueSnackbar
    );
    apiRequest(
      "post",
      "api/top-events/",
      {},
      setTopEvents,
      enqueueSnackbar
    );
    apiRequest(
      "post",
      "api/user-retention/",
      {},
      setUserRetention,
      enqueueSnackbar
    );
    apiRequest(
      "post",
      "api/traffic-acquisition/",
      {},
      setTrafficAcquisition,
      enqueueSnackbar
    );
    apiRequest(
      "post",
      "api/country-data/",
      {},
      setCountryData,
      enqueueSnackbar
    );
    apiRequest(
      "post",
      "api/gcf-active-users/",
      {},
      setActiveGCFUsers,
      enqueueSnackbar
    );
    apiRequest(
      "post",
      "api/gcf-resource-usage/",
      {},
      setTrafficAcquisitionGCF,
      enqueueSnackbar
    );
    apiRequest(
      "post",
      "api/gcf-kpis-per-quarter/",
      {},
      setKpis_per_quarter,
      enqueueSnackbar
    );
    apiRequest(
      "post",
      "api/gcf-kpis-per-organisation/",
      {},
      setKpis_per_organization,
      enqueueSnackbar
    );
    apiRequest(
      "post",
      "api/gcf-milestones-per-quarter/",
      {},
      setMilestones_per_quarter,
      enqueueSnackbar
    );
    apiRequest(
      "post",
      "api/gcf-milestones-per-organisation/",
      {},
      setMilestones_per_organization,
      enqueueSnackbar
    );
    setLoading(false);
  }, []);

  const handleFilterChange = (dataset, filter) => {
    let params = {};

    // Handle the filter logic for "This Month", "This Year", or Date Range
    if (filter === 'This Month') {
      const today = new Date();
      params = {
        startDate: new Date(today.getFullYear(), today.getMonth(), 1).toISOString(),
        endDate: today.toISOString()
      };
    } else if (filter === 'This Year') {
      const today = new Date();
      params = {
        startDate: new Date(today.getFullYear(), 0, 1).toISOString(),
        endDate: today.toISOString()
      };
    }
    else if(filter.filter === 'Year'){
      params = {
        year: filter.year,
      };
      
    }
     else if (filter.filter === 'Date Range') {
      params = {
        startDate: new Date(filter.startDate).toISOString(),
        endDate: new Date(filter.endDate).toISOString()
      };
    }


    switch (dataset) {
      case 'activeUsers':
        apiRequest("post", "api/active-users/", params, setActiveUsers, enqueueSnackbar);
        break;
      case 'activeGCFUsers':
        setActiveGCFUsers(null)
        apiRequest("post", "api/gcf-active-users/", params, setActiveGCFUsers, enqueueSnackbar);
        break;
      case 'usersByCountry':
        apiRequest("post", "api/users-country/", params, setUsersByCountry, enqueueSnackbar);
        break;
      case 'newUsers':
        apiRequest("post", "api/new-users/", params, setNewUsers, enqueueSnackbar);
        break;
      case 'topEvents':
        apiRequest("post", "api/top-events/", params, setTopEvents, enqueueSnackbar);
        break;
      case 'userRetention':
        apiRequest("post", "api/user-retention/", params, setUserRetention, enqueueSnackbar);
        break;
      case 'trafficAcquisition':
        apiRequest("post", "api/traffic-acquisition/", params, setTrafficAcquisition, enqueueSnackbar);
        break;
      case 'trafficAcquisitionGCF':
        setTrafficAcquisitionGCF(null)
        apiRequest("post", "api/gcf-resource-usage/", params, setTrafficAcquisitionGCF, enqueueSnackbar);
        break;
      case 'kpis_per_quarter':
        apiRequest("post", "api/gcf-kpis-per-quarter/", params, setKpis_per_quarter, enqueueSnackbar);
        break;
      case 'kpis_per_organization':
        apiRequest("post", "api/gcf-kpis-per-organisation/", params, setKpis_per_organization, enqueueSnackbar);
        break;
      case'milestones_per_quarter':
        apiRequest("post", "api/gcf-milestones-per-quarter/", params, setMilestones_per_quarter, enqueueSnackbar);
        break;
      case'milestones_per_organization':
        apiRequest("post", "api/gcf-milestones-per-organisation/", params, setMilestones_per_organization, enqueueSnackbar);
        break;
      default:
        console.log('Unknown dataset');
    }
  };

  if (loading) return <p>Loading...</p>;

  return (
    <div className="App">
      <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
        <TabList>
          <Tab>Google Analytics Dashboard</Tab>
          <Tab>GCF Analytics Dashboard</Tab>
        </TabList>
        <TabPanel>
          <div className="flex flex-col lg:flex-row lg:space-x-4 my-8">
            <div className="p-2 shadow-lg rounded-lg w-full flex justify-between items-center relative">
              {activeUsers != null ? <UserActivityLineChart data={activeUsers} /> : <p>Loading...</p>}
              <div className="more-button-container absolute top-2 right-2"><MoreButton dataset="activeUsers" onFilterChange={handleFilterChange} /></div>
            </div>            
          </div>
            
            <div className="flex flex-col lg:flex-row lg:space-x-4 my-8">
              <div className="p-2 shadow-lg rounded-lg w-full flex justify-between items-center relative">
              {newUsers != null ? <BarChart data={newUsers} /> : <p>Loading...</p>}
              <div className="more-button-container absolute top-2 right-2"><MoreButton dataset="newUsers" onFilterChange={handleFilterChange} /></div>
            </div>
          </div>
          <div className="flex flex-col lg:flex-row lg:space-x-4 my-8">
            <div className="p-2 shadow-lg rounded-lg w-full flex justify-between items-center relative">
              {usersByCountry != null ? <UserByCountryBarChart data={usersByCountry} /> : <p>Loading...</p>}
              <div className="more-button-container absolute top-2 right-2"><MoreButton dataset="usersByCountry" onFilterChange={handleFilterChange} /></div>
            </div>
          </div>
            
          {/* <div className="flex flex-col lg:flex-row lg:space-x-4 my-8">              
            <div className="p-2 shadow-lg rounded-lg w-full flex justify-between items-center relative">
              {userRetention != null ? <RetentionChart data={userRetention} /> : <p>Loading...</p>}
              <div className="more-button-container absolute top-2 right-2"><MoreButton dataset="userRetention" onFilterChange={handleFilterChange} /></div>
            </div>
          </div> */}
          <div className="flex flex-col lg:flex-row lg:space-x-4 my-8">
            <div className="p-2 shadow-lg rounded-lg w-full flex justify-between items-center relative">
              {trafficAcquisition != null ? <PageViewsBarChart data={trafficAcquisition} /> : <p>Loading...</p>}
              <div className="more-button-container absolute top-2 right-2"><MoreButton dataset="trafficAcquisition" onFilterChange={handleFilterChange} /></div>
            </div>
          </div>
        </TabPanel>
        <TabPanel>
          <div className="flex flex-col lg:flex-row lg:space-x-4 my-8">
            <div className="p-2 shadow-lg rounded-lg w-full flex justify-between items-center relative">

              {activeUsers != null ? <ActiveUserActivityLineChart data={trafficAcquisitionGCF} /> : <p>Loading...</p>}
              <div className="more-button-container absolute top-2 right-2"><MoreButton dataset="trafficAcquisitionGCF" onFilterChange={handleFilterChange} /></div>
            </div>
          </div>
            
            <div className="flex flex-col lg:flex-row lg:space-x-4 my-8">
              
            <div className="p-2 shadow-lg rounded-lg w-full flex justify-between items-center relative">
              {newUsers != null ? <ActiveUsersLineChart data={activeGCFUsers} /> : <p>Loading...</p>}
              <div className="more-button-container absolute top-2 right-2"><MoreButton dataset="activeGCFUsers" onFilterChange={handleFilterChange} /></div>
            </div>
          </div>
            
          <div className="flex flex-col lg:flex-row lg:space-x-4 my-8">
            
            <div className="p-2 shadow-lg rounded-lg w-full flex justify-between items-center relative">
              {kpis_per_quarter != null ? <KPIChart data={kpis_per_quarter} /> : <p>Loading...</p>}
              <div className="more-button-container absolute top-2 right-2"><MoreButton dataset="kpis_per_quarter" onFilterChange={handleFilterChange}  filterByYear = "true"/></div>
            </div>
          </div>
            
          <div className="flex flex-col lg:flex-row lg:space-x-4 my-8">
            
           <div className="p-2 shadow-lg rounded-lg w-full flex justify-between items-center relative">
              {kpis_per_organization != null ? <StackedBarChart data={kpis_per_organization} /> : <p>Loading...</p>}
              <div className="more-button-container absolute top-2 right-2"><MoreButton dataset="kpis_per_organization" onFilterChange={handleFilterChange}  filterByYear = "true"/></div>
            </div>
          </div>
            
          <div className="flex flex-col lg:flex-row lg:space-x-4 my-8">
            
             <div className="p-2 shadow-lg rounded-lg w-full flex justify-between items-center relative">
              {milestones_per_quarter != null ? <MilestonesChart data={milestones_per_quarter} /> : <p>Loading...</p>}
              <div className="more-button-container absolute top-2 right-2"><MoreButton dataset="milestones_per_quarter" onFilterChange={handleFilterChange} filterByYear = "true"/></div>
            </div>
          </div>
            
            <div className="flex flex-col lg:flex-row lg:space-x-4 my-8">
              
               <div className="p-2 shadow-lg rounded-lg w-full flex justify-between items-center relative">
                {milestones_per_organization != null ? <MilestonesChartByEntity data={milestones_per_organization} /> : <p>Loading...</p>}
                <div className="more-button-container absolute top-2 right-2"><MoreButton dataset="milestones_per_organization" onFilterChange={handleFilterChange}  filterByYear = "true"/></div>
              </div>
            </div>
        </TabPanel>
      </Tabs>
    </div>
  );
}