import React from 'react';
import { Line, Bar, Pie } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, BarElement, ArcElement, Title, Tooltip, Legend } from 'chart.js';

// Register scales and elements
ChartJS.register(
  CategoryScale,   // For categorical x-axis
  LinearScale,     // For linear y-axis
  PointElement,    // For points in line chart
  LineElement,     // For line chart lines
  BarElement,
  ArcElement,
  Title,           // Chart title
  Tooltip,         // Tooltips
  Legend           // Legends
);

const ActiveUserActivityLineChart = ({ data }) => {
    if (!data || data.length === 0) {
      return <p>No data available</p>; // Fallback when data is not available
    }
  
    const labels = data.map(item => item.url_name);  
    const activeUsersData = data.map(item => item.distinct_user_count);  
    const userActionsData = data.map(item => item.total_actions);   
  
    const chartData = {
      labels: labels,
      datasets: [
        {
          label: 'Users',
          data: activeUsersData,
          borderColor: 'rgba(153, 102, 255, 1)',  // Color for New Users
          backgroundColor: 'rgba(153, 102, 255, 0.2)',
          fill: false,  // Set to false for no area fill
          tension: 0.1,  // Smooth lines
        },
        {
          label: 'Interaction',
          data: userActionsData,
          borderColor: 'rgba(75, 192, 192, 1)',  // Color for Active Users
          backgroundColor: 'rgba(75, 192, 192, 0.2)',
          fill: false,  // Set to false for no area fill
          tension: 0.1,  // Smooth lines
        },
      ],
    };
  
    const options = {
      responsive: true,
      plugins: {
        title: {
          display: true,
          text: "Users and Resource Utilization",
        },
        legend: {
          position: 'top',
        },
      },
      scales: {
        x: {
          title: {
            display: true,
            text: 'Module',
          },
        },
        y: {
          title: {
            display: true,
            text: 'activities',
          },
          beginAtZero: true,
        },
      },
    };
  
    return <Line data={chartData} options={options} />;
  };

  const ActiveUsersLineChart = ({ data }) => {
    if (!data || data.length === 0) {
      return <p>No data available</p>; // Fallback when data is not available
    }
  
    const labels = data.map(item => item.login_date);  
    const activeUsersData = data.map(item => item.user_count);    
  
    const chartData = {
      labels: labels,
      datasets: [
        {
          label: 'Active Users',
          data: activeUsersData,
          borderColor: 'rgba(153, 102, 255, 1)',  // Color for New Users
          backgroundColor: 'rgba(153, 102, 255, 0.2)',
          fill: false,  // Set to false for no area fill
          tension: 0.1,  // Smooth lines
        },
      ],
    };
  
    const options = {
      responsive: true,
      plugins: {
        title: {
          display: true,
          text: "Active Users over time",
        },
        legend: {
          position: 'top',
        },
      },
      scales: {
        x: {
          title: {
            display: true,
            text: 'Date',
          },
        },
        y: {
          title: {
            display: true,
            text: 'Active Users',
          },
          beginAtZero: true,
        },
      },
    };
  
    return <Line data={chartData} options={options} />;
  };
  
  const KPIChart = ({ data }) => {
    // Extracting KPI names and data per quarter
    const kpiNames = data.Q1.map((item) => item.kpi);
  
    const chartData = {
      labels: kpiNames,
      datasets: [
        {
          label: "Q1",
          backgroundColor: "rgba(75, 192, 192, 0.6)",
          data: data.Q1.map((item) => item.achieved),
        },
        {
          label: "Q2",
          backgroundColor: "rgba(153, 102, 255, 0.6)",
          data: data.Q2.map((item) => item.achieved),
        },
        {
          label: "Q3",
          backgroundColor: "rgba(255, 159, 64, 0.6)",
          data: data.Q3.map((item) => item.achieved),
        },
        {
          label: "Q4",
          backgroundColor: "rgba(255, 99, 132, 0.6)",
          data: data.Q4.map((item) => item.achieved),
        },
      ],
    };
  
    const options = {
      indexAxis: "y", // makes the chart horizontal
      responsive: true,
      plugins: {
        legend: {
          position: "top",
        },
        title: {
          display: true,
          text: "KPI Achievements by Quarter",
        },
      },
      scales: {
        x: {
          beginAtZero: true,
        },
      },
    };
  
    return <Bar data={chartData} options={options} />;
  };

  const StackedBarChart = ({data}) => {
    const orgNames = Object.keys(data);
    const labels = [...new Set(orgNames.flatMap(org => data[org].kpis.map(kpi => kpi.kpi)))];
    // const datasets = labels.map((label, index) => ({
    //   label,
    //   data: orgNames.map(org => data[org].kpis.find(kpi => kpi.kpi === label)?.achieved || 0),
    //   backgroundColor: `rgba(${50 + index * 20}, ${150 + index * 10}, ${100 + index * 15}, 0.6)`,
    // }));

    const datasets = labels.map((label, index) => {
      const colorMultiplier = index * 20;
      // Ensure the RGB values are within the range and do not result in a white background
      // const r = Math.min(50 + colorMultiplier, 255);
      // const g = Math.min(150 + colorMultiplier, 255);
      // const b = Math.min(100 + colorMultiplier, 255);
      const r = Math.floor(Math.random() * 150) + 50;  // Ensure the red value is between 50 and 200
      const g = Math.floor(Math.random() * 150) + 50;  // Ensure the green value is between 50 and 200
      const b = Math.floor(Math.random() * 150) + 50;
      const backgroundColor = `rgba(${r}, ${g}, ${b}, 0.6)`;
  
      return {
        label,
        data: orgNames.map(org => data[org].kpis.find(kpi => kpi.kpi === label)?.achieved || 0),
        backgroundColor,
      };
    });
  
    
    const chartData = {
      labels: orgNames,
      datasets: datasets,
    };

    const options = {
      plugins: {
        title: {
          display: true,
          text: "KPI Achievements by Organization",
        },
        tooltip: {
          mode: "index",
          intersect: false,
        },
      },
      responsive: true,
      scales: {
        x: { stacked: true },
        y: { stacked: true },
      },
    };
    
    return (
      <div style={{ width: "80%", margin: "auto" }}>
        <Bar data={chartData} options={options} />
      </div>
    );
  };
  const MilestonesChart = ({ data }) => {
    // Extracting KPI names and data per quarter
    const milestoneNames = data.Q1.map((item) => item.milestone);
  
    const chartData = {
      labels: milestoneNames,
      datasets: [
        {
          label: "Q1",
          backgroundColor: "rgba(75, 192, 192, 0.6)",
          data: data.Q1.map((item) => item.achieved),
        },
        {
          label: "Q2",
          backgroundColor: "rgba(153, 102, 255, 0.6)",
          data: data.Q2.map((item) => item.achieved),
        },
        {
          label: "Q3",
          backgroundColor: "rgba(255, 159, 64, 0.6)",
          data: data.Q3.map((item) => item.achieved),
        },
        {
          label: "Q4",
          backgroundColor: "rgba(255, 99, 132, 0.6)",
          data: data.Q4.map((item) => item.achieved),
        },
      ],
    };
  
    const options = {
      indexAxis: "y", // makes the chart horizontal
      responsive: true,
      plugins: {
        legend: {
          position: "top",
        },
        title: {
          display: true,
          text: "Milestones Achievements by Quarter",
        },
      },
      scales: {
        x: {
          beginAtZero: true,
        },
      },
    };
  
    return <Bar data={chartData} options={options} />;
  };
  const MilestonesChartByEntity = ({ data }) => {
    const generateColor = () => {
      // Randomly generate RGB values within a certain range
      const r = Math.floor(Math.random() * 150) + 50;  // Ensure the red value is between 50 and 200
      const g = Math.floor(Math.random() * 150) + 50;  // Ensure the green value is between 50 and 200
      const b = Math.floor(Math.random() * 150) + 50;  // Ensure the blue value is between 50 and 200
      return `rgba(${r}, ${g}, ${b}, 0.6)`; // Returning a color with 60% opacity
    };
    const orgNames = Object.keys(data);
    const labels = [...new Set(orgNames.flatMap(org => data[org].milestones.map(milestone => milestone.milestone)))];
    const datasets = labels.map((label, index) => ({
      label,
      data: orgNames.map(org => data[org].milestones.find(milestone => milestone.milestone === label)?.achieved || 0),
      // backgroundColor: `rgba(${50 + index * 20}, ${150 + index * 10}, ${100 + index * 15}, 0.6)`,
      backgroundColor: generateColor(),
    }));
    const chartData = {
      labels: orgNames, // Labels will be the milestone names
      datasets: datasets, // Datasets will be the
    };
  
    const options = {
      responsive: true,
      plugins: {
        title: {
          display: true,
          text: 'Milestone Achievements by Entity',
        },
        legend: {
          position: 'top',
        },
      },
      scales: {
        x: {
          beginAtZero: true,
        },
        y: {
          beginAtZero: true,
        },
      },
    };
  
    return <Bar data={chartData} options={options} />;
  };
  const MilestonesChartByEntitys = ({ data }) => {
    // Prepare the chart data
    const organizations = Object.keys(data); // ['GIZ', 'E41', 'DeKUT', ...]
    const milestones = data[organizations[0]].milestones.map(milestone => milestone.milestone);
    
    const achievedData = organizations.map(organization => {
      return data[organization].milestones.reduce((sum, milestone) => sum + milestone.achieved, 0);
    });
  
    const chartData = {
      labels: organizations, // X-axis labels (Organizations)
      datasets: [
        {
          label: 'Milestones Achieved',
          data: achievedData, // Y-axis data (Total milestones achieved for each organization)
          backgroundColor: 'rgba(75, 192, 192, 0.2)',
          borderColor: 'rgba(75, 192, 192, 1)',
          borderWidth: 1
        }
      ]
    };
  
    const chartOptions = {
      responsive: true,
      plugins: {
        title: {
          display: true,
          text: 'Milestones Achievement by Organization'
        },
        tooltip: {
          callbacks: {
            label: (tooltipItem) => `Achieved: ${tooltipItem.raw}`
          }
        }
      },
      scales: {
        x: {
          title: {
            display: true,
            text: 'Organizations'
          }
        },
        y: {
          title: {
            display: true,
            text: 'Milestones Achieved'
          },
          beginAtZero: true
        }
      }
    };
  
    return <Bar data={chartData} options={chartOptions} />;
  };
  
  export {ActiveUserActivityLineChart, ActiveUsersLineChart, KPIChart, StackedBarChart, MilestonesChart,MilestonesChartByEntity}